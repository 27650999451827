import React from 'react';
import * as Sentry from '@sentry/browser';

import App from 'next/app';
import Head from 'next/head';
import { siteConfig } from '../src/config';
//import { withReduxSaga } from '../src/redux/store';
import { Provider } from 'react-redux';
import { themeConfig } from '../src/config';
import { CSSBaseline, ThemeProvider } from '@l_ine/core';
import { getCookie } from '../src/helpers/session';
import progressBar from '../src/helpers/routerProgress';

import store from '../src/store/store';
import { wrapper } from '../src/store/store';

progressBar();

if (process.env.NODE_ENV === 'production') {
  if (Sentry && Sentry.init && typeof Sentry.init === 'function') {
    Sentry.init({
      environment: process.env.NODE_ENV,
      dsn:
        'https://87b45df7ad2e4bb58e381efe06f49b9b@o206122.ingest.sentry.io/1503615',
    });
  }
}

class MyApp extends App<{ [name: string]: any }> {
  static async getInitialProps({ Component, ctx }: { Component: any; ctx: any }) {
    let pageProps = {};

    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return { pageProps };
  }

  componentDidCatch(error: any, errorInfo: any) {
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    }
    super.componentDidCatch(error, errorInfo);
  }

  render() {
    const { Component, pageProps } = this.props;
    return (
      <React.Fragment>
        <Head>
          <title>{siteConfig.siteName}</title>
          <meta
            name="viewport"
            content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
          />
        </Head>
        <Provider store={store}>
          <ThemeProvider theme={themeConfig.current}>
            <CSSBaseline />
            <Component {...pageProps} />
          </ThemeProvider>
        </Provider>
      </React.Fragment>
    );
  }
}

export default wrapper.withRedux(MyApp);
