import cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';

interface Decoded {
  iat: any;
}

interface TokenToDelete {
  key: string;
  iat: number;
}

export const setCookie = (key: string, value: any, remember: boolean) => {
  if (process.browser && value) {
    const allCookiesKeys = Object.keys(cookie.get()).filter((it) =>
      it.includes('id_token')
    );
    if (allCookiesKeys.length >= 10) {
      const tokenToDelete: TokenToDelete = {
        key: '',
        iat: 0,
      };
      for (const cookieName of allCookiesKeys) {
        if (cookieName !== 'id_token' && cookieName !== key) {
          //@ts-ignore
          const decoded: Decoded = jwtDecode(cookie.get(cookieName));
          if (decoded.iat < tokenToDelete.iat || !tokenToDelete.iat) {
            tokenToDelete.iat = decoded.iat;
            tokenToDelete.key = cookieName;
          }
        }
      }
      if (tokenToDelete.iat) {
        cookie.remove(tokenToDelete.key);
      }
    }
    cookie.set(key, value, {
      expires: remember ? 7 : 1,
      path: '/',
      //secure: true
    });
  }
};

export const removeCookie = (key: string) => {
  if (process.browser) {
    cookie.remove(key, {
      path: '/',
      //secure: true
    });
  }
};

export const getCookie = (key: string, req?: any) => {
  return process.browser
    ? getCookieFromBrowser(key)
    : getCookieFromServer(key, req);
};

const getCookieFromBrowser = (key: string) => {
  return cookie.get(key);
};

const getCookieFromServer = (key: string, req: any) => {
  if (!req) return;
  if (!req.headers.cookie) {
    return;
  }
  const rawCookie = req.headers.cookie
    .split(';')
    .find((c: string) => c.trim().startsWith(`${key}=`));
  if (!rawCookie) {
    return;
  }
  if (rawCookie.split('=')[1] === 'undefined') {
    return;
  } else {
    return rawCookie.split('=')[1];
  }
};
